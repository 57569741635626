$('.inventory-img-link a').magnificPopup({
  type: 'image'
  // other options
});

///// Stock Status - Sort
$('.sort form').change(function(){
  $(this).submit();
});


///// Cart - Remove Item - Check if checked
function checkform() {
  
  let i;
  let removeArray = [];
  let removeItem = document.forms['cart']['remove'];
  let removeLength = removeItem.length;
  let removeCheck = false;
  // Convert Form Input items (radioNodeList) into array
  if (removeLength) {
    for (i = 0; i < removeLength; i++) {
      removeArray.push(removeItem[i]);
    };
  } else {
    removeArray.push(removeItem);
  }
  let removeArrayLength = removeArray.length;

  // Loop through Array to see if checked
  for (i = 0; i < removeArrayLength; i++) {
    if (removeArray[i].checked == true) {
      //console.log('something is checked');
      removeCheck = true;
    }
  };
  
  if (removeCheck == true) {
      document.forms['cart'].submit();
  } else {
      //console.log('nothing is checked');
      $("#ware-box2")
        .css({"display":"flex"})
        .animate({
          opacity: 1
      });
      return false;
  }
}

///// Cart - Close popup
$('#close').click(function(e){
     $("#ware-box2").animate({
        opacity:0
     },
        function(){
       $(this).hide();
     });					 
});


///// Filter results by part number
let $searchField = $('input[name="partNumber"]');
let $searchButton = $('#searchSKU');
let $clearButton = $('#clearSKU');
let $sku = $searchButton.parent().parent().find('input');

let $noMatches = $('.no-matches');
let $resultCountEmpty = $('.result-count-empty');
let $resultCount = $('.result-count');

if($('.filter-part-number').length){
  $clearButton.hide();
}

$searchField.keypress(function(event) {
  if(event.keyCode == 13 || event.which == 13) {
    $searchButton.trigger('click');
    event.preventDefault;
  }
});

$searchButton.click(function(){
  let $skuValue = $sku.val();
  if ($skuValue.length) {
    showLoading($skuValue);
    $searchButton.hide();
    $clearButton.show();
  }
});

$clearButton.click(function(){
  $sku.val('');
  $(this).hide();
  $searchButton.show();
  showLoading();
});

function showLoading(partNumber) {
  $('.loading-screen').first().fadeIn(function(){
    if (partNumber != undefined) {
      checkList(partNumber);
    } else {
      showCompleteList();
    }
  });
}

function hideLoading() {
  $('.loading-screen').fadeOut();
}

function checkList(partNumber) {
  $('.view-list > div').each(function(){
    let $listItem = $(this);
    let $listItemSKU = $listItem.data('sku');
    //console.log($listItemSKU);
    //console.log($listItem.data('sku'));
    if ( $listItemSKU.toLowerCase() != partNumber.toLowerCase() ) {
      $listItem.hide();
    } else {
      $listItem.show();
    }
  });
  hideLoading();
}

function showCompleteList() {
  $('.view-list > div').each(function(){
    $(this).show();
  });
  hideLoading();
}





///// Inventory Popup

var displayHeight = window.innerHeight;
var headerHeight = $('header').outerHeight();
var viewPadding = 100;
var mobileHeight = displayHeight - headerHeight - viewPadding;
//console.log('display height: ' + displayHeight);
//console.log('header height: ' + headerHeight);
//console.log('mobile height: ' + mobileHeight);
if (displayHeight<1100) {
  $('#ware-box .item-popup').css('height',mobileHeight);  
}








///// Cart - Remove Item
$('.item-remove-popup').click(function(e){
  let itemID = $(this).data('itemid');
  let itemName = $(this).data('itemname');
  let itemSku = $(this).data('sku');
  //console.log(itemID);
  //console.log(itemSku);
  $("#ware-box2").animate({
    opacity:1
  },
    function(){
    $(this).css({"display":"flex"});
    $(this).find('.item-name').html(itemSku);
    $(this).find('#remove').prop('href','?remove='+itemID);
    $(this).show();
  });					 
});




///// checkout submit

if($('#checkout').length) {
  $("#submit,#checkout-submit, #checkout-mobile-submit").click(function() {
    var fail = false;
    $(".required").each(function() {
      if($(this).val() == "") {
        fail = true;
        $(this).css("background-color", "red");
      } else {
        $(this).css("background-color", "");
      }
    });
    
    if(fail) {
      alert("Please fill out all fields in red.");
      return false;
    } else {
      $("#form").submit();
    }
  });
}

// get more inventory
$('#inventory .navigation a').click(function(){
  var $this = $(this);
  var currentPage = $this.attr('data-page');
  var nextPage = parseInt(currentPage) + 1;
  var currentDisplay = $this.parent().prev();
  var getLast = $this.parent().prev().find('.column:last-child');
  var currentListCount = currentDisplay.find('.column').length;
  var nextListCount = currentListCount + 12;
  var maxListCount = $this.attr('data-max');
  var inventoryType = $this.attr('data-inventory');
  
  // remove load more link
  if(nextListCount >= maxListCount) {
    $this.fadeOut();
  }
  
  // update page
  loadMoreGallery($this,nextPage)
  
  // display feed
  if(inventoryType == 'caps') {
    $.get('/ajax/getMoreCapsInventory.cfm' + 
      '?style=' + $this.data('style') +
      '&finish=' + $this.data('finish') +
      '&type=' + $this.data('type') +
      '&stock=' + $this.data('stock') +
      '&special=' + $this.data('special') +
      '&clearance=' + $this.data('clearance') +
      '&pagesize=' + $this.data('pagesize') +
      '&order=' + $this.data('order') +
      '&display=' + $this.data('display') +
      '&page=' + currentPage, function getNewImages(data) {
      $(currentDisplay).append(data);
    });
  } else {
    $.get('/ajax/getMoreInventory.cfm' + 
      '?wheel=' + $this.data('wheel') +
      '&finish=' + $this.data('finish') +
      '&diameter=' + $this.data('diameter') +
      '&width=' + $this.data('width') +
      '&bolt=' + $this.data('bolt') +
      '&bsm=' + $this.data('bsm') +
      '&pagesize=' + $this.data('pagesize') +
      '&order=' + $this.data('order') +
      '&category=' + $this.data('category') +
      '&stock=' + $this.data('stock') +
      '&partnumber=' + $this.data('partnumber') +
      '&display=' + $this.data('display') +
      '&page=' + currentPage, function getNewImages(data) {
      $(currentDisplay).append(data);
    });
  }
});

function loadMoreGallery(element,page) {
  element.attr('data-page',page);

}