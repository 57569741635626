if($('#wheel').length) {
  // cart - quantity updates
  let qty = $('input[name="quantity"]');
  let currentQty = qty.val();
  setQty(currentQty);
  qty.change(function(){
    qty = $('input[name="quantity"]');
    currentQty = qty.val();
    setQty(currentQty);
  });

  function setQty(qty) {
    $('.snipcart-add-item').attr('data-item-quantity',qty);
  }

  // cart - get part numbers
  $('#products').hide();
  if(!$('.package').length) {
    $('.cart-filter .snipcart-add-to-cart').hide();
  }
  $('.cart-filter .cart-price').hide();
  $('.cart-filter .paint-options, .cart-filter .paint-display').hide();
  // products dropdown
  $('#diameters').on('change', function() {
    var diameter = $(this).val();
    let wheelID = $('.cart-filter').attr('data-wheel');
    let finishID = $('.cart-filter').attr('data-finish');
    let link = $('.cart-filter').attr('data-link');

    var url = '/ajax/getSKU.cfm?wheel=' + wheelID + '&diameter=' + diameter + '&finish=' + finishID + '&link=' + link;
    try {
      $.ajax({
        url: url,
        success: function (data) {
          //console.log('this seems to works');
          $('#products').fadeIn().html(data);
        },
      });
    } catch (err) {
      window.location.reload();
    }
  });
  
  // cart button
  $('#products').on('change', function() {
    //console.log('here is the produts stufs');
    let productInfo = $(this).find('option:selected');
    let productID = productInfo.val();
    let productDescription = productInfo.attr('data-description');
    let productPrice = productInfo.attr('data-price');
    let productLink = productInfo.attr('data-link');
    let productPriceString = '$' + productPrice + ' (ea.)';
    //console.log(productID,productDescription,productPrice,productLink);
    let productQty = productInfo.attr('data-qty');
    let cart = $('.snipcart-add-to-cart');
    let priceDisplay = $('.cart-filter .cart-price');
    let paintDisplay = $('.cart-filter .paint-options, .cart-filter .paint-display');
    let stockDisplay = $('.in-stock h4');
    let inStock = 'Out of Stock';
    cart.fadeIn();
    priceDisplay.fadeIn();
    paintDisplay.fadeIn();
    $('.snipcart-add-item,.wheel-information')
      .attr('data-item-id',productID)
      .attr('data-item-price',productPrice)
      .attr('data-item-url',productLink)
      .attr('data-item-description',productDescription);
    document.querySelector('meta[property="product:price:amount"]').setAttribute('content',productPrice);
    document.querySelector('meta[property="product:retailer_item_id"]').setAttribute('content',productID);
    console.log('pewpew')
    $('.cart-price .price span').html(productPriceString);
    if(productQty > 0) {
      inStock = ('In Stock');
    }
    stockDisplay.html(inStock);
  });


  // select paint
  function selectedPaint(item) {
    //console.log(item);
    let itemList = $('#paint-options-popup .item-list a');
    let paintInfo = document.querySelector('.paint-information');

    itemList.each(function(){
      $(this).removeClass('selected');
    });

    item.classList.add('selected');
    paintInfo.classList.add('item-information');
  }
  // add paint information 
  function paintInformation(sku,price,color,description,url,image) {
    let cartInfo = $('.paint-information');
    let displayImage = $('.paint-image');
    let displayColor = $('.paint-color');
    //console.log(url);
    // update snipcart info
    cartInfo.attr('data-item-id',sku)
      .attr('data-item-price',price)
      .attr('data-item-url',url)
      .attr('data-item-image',image)
      .attr('data-item-description',description)
      .attr('data-item-name',color);
    // update display
    displayImage.html('<img src="'+image+'"/>');
    displayColor.html(color);
  }

  // clear paint information
  function clearPaintInfo() {
    let itemList = $('#paint-options-popup .item-list a');
    let paintInfo = document.querySelector('.paint-information');
    let displayImage = $('.paint-image');
    let displayColor = $('.paint-color');

    itemList.each(function(){
      $(this).removeClass('selected');
    });
    paintInfo.classList.remove('item-information');
    displayImage.html('');
    displayColor.html('');
  }

  // update quantity
  $('input[name="quantity"]').change(function(){
    let value = this.value;
    let cartQuantity = $('.wheel-information');
    let paintQuantity = $('.paint-information');
    cartQuantity.attr('data-item-quantity',value);
    paintQuantity.attr('data-item-quantity',value);
    //console.log('the cart qty is: ', cartQuantity);
  });



  ///////// snipcart /////////////



  // build items array
  function buildItems() {
    let items = [];
    $('.item-information').each(function(){
      let item = {};
      item.id = $(this).attr('data-item-id');
      item.price = $(this).attr('data-item-price');
      item.url = $(this).attr('data-item-url');
      item.description = $(this).attr('data-item-description');
      item.image = $(this).attr('data-item-image');
      item.name = $(this).attr('data-item-name');
      item.quantity = $(this).attr('data-item-quantity');
      let tempField = $(this).attr('data-item-custom1-name');
      item.customFields = [{
        "name":tempField,
        "displayValue":"",
        "operation": null,
        "type": "textbox",
        "options": "",
        "required": false,
        "value": "",
        "optionsArray": null}];
      //item.quantity = $(this).prev().find('input[name="quantity"]').val();
      items.push(item);
    });
    return items;
  }


  // snipcart
  document.addEventListener('snipcart.ready', () => {
    // You can safely use window.Snipcart here

    $('.add-to-cart').click(function(){
      let products = buildItems();
      checkCart(products);    
    });

    function checkCart(products) {
      let cartList = Snipcart.store.getState().cart.items.items;
      let idList = cartList.map(e => ({id:e.id,uniqueId:e.uniqueId,quantity:e.quantity}));
      let updateList = [];
      let newList = [];

      for (let i = 0; i < products.length; i++ ){
        let itemChecked = cartList.find(e => e.id === products[i].id);
        if(itemChecked) {
          updateList.push(products[i]);
        } else {
          newList.push(products[i]);
        }
      }
      
      // update cart
      if(updateList.length) {
        updateCart(updateList,idList);
      }
      
      // add to cart
      addToCart(newList);

      openTheCart();
    }
    
    function addToCart(items) {
      for(var i = 0; i < items.length; i++) {
        Snipcart.api.cart.items.add(items[i]);
      }
    }

    function updateCart(items,cart) {
      for(var i = 0; i < items.length; i++) {
        let cartMatch = cart.find(e => e.id === items[i].id);
        let qty = parseInt(cartMatch.quantity) + parseInt(items[i].quantity);
        let update = {
          uniqueId: cartMatch.uniqueId,
          quantity: qty};
        Snipcart.api.cart.items.update(update);
      }
    }

    function openTheCart(){
      Snipcart.api.theme.cart.open();
    }

  });
}