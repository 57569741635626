$('.js-trim-select').on('change', function() {
  var value = $(this).val();
  var url = '/ajax/connector.cfc?method=setBodyType&strBodyType=' + value;
  $.ajax({
    method: 'POST',
    url: url
  }).done(function() {
    window.location.href = '/accessory-brands/';
  });
});

$('.js-filterCapType').change(function changeMake() {
  $('#accessory-search form').submit();
});


if($('#accessory').length) {
  // cart - quantity updates
  let qty = $('input[name="quantity"]');
  let currentQty = qty.val();
  setQty(currentQty);
  qty.change(function(){
    qty = $('input[name="quantity"]');
    currentQty = qty.val();
    setQty(currentQty);
  });

  function setQty(qty) {
    $('.snipcart-add-item').attr('data-item-quantity',qty);
  }
}