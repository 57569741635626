$('.fitment-open').magnificPopup({
  items: {
    src: '#fitment-popup',
    type: 'inline'
  },
  callbacks: {
    close: function () {
      location.reload();
    }
  }
});

$('.checkFitment-open').magnificPopup({
  items: {
    src: '#checkFitment-popup',
    type: 'inline'
  }
});

$('.js-search-btn').magnificPopup({
  items: {
    src: '<div class="white text-center processing">' +
      '<i class="fa fa-spinner fa-spin fa-4x"></i>' +
      '</div>',
    type: 'inline',
    modal: true
  },
  callbacks: {
    open: function () {
      $('#search-form').submit();
    }
  }
});

$('.js-tireFitment').magnificPopup({
  items: {
    src: '#app-guide-tires-popup',
    type: 'inline'
  }
});

$('.js-accessoryFitment').magnificPopup({
  items: {
    src: '#app-guide-accessory-popup',
    type: 'inline'
  }
});

$('.js-fitmentCheck').magnificPopup({
  items: {
    src: '#app-guide-fitment-check-popup',
    type: 'inline'
  }
});

// Gallery Detail Quote Popup
$('.open-galleryDetail-quote-popup').magnificPopup({
  items: {
    src: '#galleryDetail-quote-popup',
    type: 'inline'
  }
});

// RFQ Popup
$('.open-quote-popup').magnificPopup({
  items: {
    src: '#quote-popup',
    type: 'inline'
  }
});

// Gallery View Popup
$('.wheel-gallery-photo').magnificPopup({
  type: 'image',
  gallery: {
    enabled: true,
    navigateByImgClick: true,
    preload: [0, 1]
  }
});

// Wheel Detail Quote Popup
$('.open-detail-quote-popup').magnificPopup({
  items: {
    src: '#detail-quote-popup',
    type: 'inline',
    closeBtnInside: false
  }
});

// Tire Detail Quote Popup
$('.open-tireDetail-quote-popup').magnificPopup({
  items: {
    src: '#tireDetail-quote-popup',
    type: 'inline'
  }
});

// Accessory Detail Quote Popup
$('.open-accessoryDetail-quote-popup').magnificPopup({
  items: {
    src: '#accessoryDetail-quote-popup',
    type: 'inline'
  }
});

$('.cart-open').magnificPopup({
  items: {
    src: '#cart-popup',
    type: 'inline'
  }
});

$('a.video, #videos a, .popup-video').magnificPopup({
  disableOn: 700,
  type: 'iframe',
  mainClass: 'mfp-fade',
  removalDelay: 160,
  preloader: false,
  fixedContentPos: false
});

// Wheel Packages Quote Popup
$('.open-package-quote-popup').on('click', function () {
  var packageName = $(this)[0].parentElement.children[0].innerText;
  $.magnificPopup.open({
    items: {
      src: '#package-quote-popup',
      type: 'inline'
    },
    callbacks: {
      beforeOpen: function () {
        $('#contact-form').find('.product-info').val(packageName);
      }
    }
  });
});

// Wheel Paint Popup
$('.open-wheel-paint-popup').magnificPopup({
  items: {
    src: '#paint-options-popup',
    type: 'inline'
  },
  callbacks: {
    open: function() {
      let activePopup = this;
      $('#paint-options-popup a').click(function(){
        let sku = this.dataset.sku;
        let price = this.dataset.price;
        let color = this.dataset.color;
        let image = this.dataset.image;
        let description = this.dataset.description;
        let url = this.dataset.url;
        selectedPaint(this);
        paintInformation(sku,price,color,description,url,image);
        //activePopup.close();
      });

      $('.paint-close').click(function(){
        activePopup.close();
      });
      $('.paint-clear').click(function(){
        clearPaintInfo();
      })
    }
  }
});